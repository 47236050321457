@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }

  ul li {
    position: relative;
    padding-left: $spacing-medium;
    margin-bottom: $spacing-medium;
  }

  ul li:before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    color: $orange;
  }
}
