@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  blockquote {
    @include quote;

    font-style: italic;
    color: $darkGreen;
    padding: $spacing-medium; 
    margin: $spacing-medium 0; 
    border-left: 5px solid $light-gray;
    background-color: $sand;
  }
  
  blockquote:before {
    content: "“"; /* Add an opening quote */
    font-size: 3rem;
    color: $light-gray; /* Light color for decorative effect */
    position: relative;
    top: 10px;
    left: -10px;
  }
  
  blockquote:after {
    content: "”"; /* Add a closing quote */
    font-size: 3rem;
    color: $light-gray;
    position: relative;
    top: 10px;
    right: -10px;
  }
}

.Stop {
  color: $darkOrange;
}