@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Conteiner {
  &::before {
    content: '';
    position: absolute;
    padding: $spacing-extra-small;

    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $lightGreen;
    transform: translate(-50%, -50%);
    z-index: -1;

    opacity: 0.5;
  }
}

.Arrow {
  cursor: pointer;

  transition: opacity 0.3s;
}

.Hidden {
  opacity: 0;
}

.Arrow_left {
  transform: rotate(180deg);
}
