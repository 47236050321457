@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  position: fixed;
  top: 0;
  right: 0;
  background-color: transparent;
  z-index: $first-layer;
  width: 100%;
}

.Header {
  @include border(bottom);
  
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: $first-layer + 2;
  background-color: $cream;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: flex-end;
}

.UserInfo {
  display: flex;
}

.Hamburger {
  padding: $spacing-medium;

  @media (min-width: $large-screen) {
    padding: $spacing-large;
  }
}

.Menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $first-layer + 1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: translateX(100%);

  width: 90vw;
  height: 100vh;
  padding: $spacing-extra-large 0;
  background-color: $cream;
  border-left: $sand-border;

  @media (min-width: $large-screen) {
    width: fit-content;
    padding: $spacing-extra-large * 2 $spacing-extra-large;
  }

  opacity: 0;
}

.MenuOpen {
  transform: translateX(0);
  opacity: 1;
}

.Divider {
  @include border(bottom);
}

.LoginButton {
  padding: 0 $spacing-medium;
  color: $lightOrange;
}

.RegisterButton {
  padding: 0 $spacing-medium;
  color: $orange;
}