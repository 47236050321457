@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Page {
  width: 100%;
  
  &_pink {
    background-color: $pink;
  }

  &_darkGreen {
    background-color: $darkGreen;
  }

  &_cream {
    background-color: $cream;
  }

  &_sand {
    background-color: $sand;
  }
}

.BorderTop {
  border-top: 1px solid $sand;
}

.BorderBottom {
  border-bottom: 1px solid $sand;
}

.SoloPage {
  height: 100vh;
}

.FullHeight {
  @media (min-width: $medium-screen) {
    height: 100vh;
  }
}
