@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  width: 100%;
  height: 100%;

  padding: $spacing-large $spacing-medium $spacing-medium;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.Logo {
  display: flex;

  justify-content: center;
}

.LogoImage {
  height: auto;
}

.Headline {
  @include headline-font;

  margin: $spacing-large 0;

  text-align: center;
  
  @media (min-width: $large-screen) {
    width: 50%;
  }

  color: $orange;
}

.CallToActions {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-top: $spacing-large;

  @media screen and (min-width: $medium-screen) {
    flex-direction: row;
  }
}
