@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: flex;
  flex-direction: column;

  @media (min-width: $large-screen) {
    height: 100%;
    flex-direction: row;

    & > div {
      width: 50%;
    }
  }

  & > div {
    @include paddings;
  }

  p {
    @include normal-text-font;
    margin-bottom: 0;
  }

  h1 {
    @include extra-large-title-font;

    margin: 0 0 $spacing-large;
  }
}

.LeftSide {
  padding-top: $spacing-medium;
  padding-bottom: $spacing-medium;

  @media (min-width: $large-screen) {
    padding-top: 0;
    padding-bottom: 0;

    border-bottom: 0 !important;
  }
}

.ImageWrapper {
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 300px;

  @media (min-width: $large-screen) {
    height: 100%;
    overflow: visible;
  }
}

.RightSide {
  @include normal-text-font;

  @media (min-width: $large-screen) {
  }
}

.Image {
  position: absolute;
  object-fit: cover;
  object-position: center;

  width: 100%;
  height: 100%;

  @media (min-width: $large-screen) {
    height: inherit;
    max-width: 100%;
    position: inherit;
  }
}

.Theme_cream {
  @media (min-width: $large-screen) {
    & > div {
      &:first-child {
        border-right: $sand-border;
      }
    }
  }

  h3 {
    color: $orange;
  }

  p {
    color: $darkGreen;
  }

  .LeftSide {
    border-bottom: $sand-border;
  }
}

.Theme_darkGreen {
  @media (min-width: $large-screen) {
    & > div {
      &:first-child {
        border-right: $light-green-border;
      }
    }
  }

  h3 {
    color: $cream;
  }

  p {
    color: $lightGreen;
  }

  .LeftSide {
    border-bottom: $light-green-border;
  }
}
