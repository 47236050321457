@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.Header {
  @include paddings;

  display: flex;
  justify-content: space-between;

  color: $cream;
  border-bottom: $light-green-border;

  h2 {
    @include extra-large-title-font;
  }
}

.Content {
  position: relative;

  height: 100%;
}

.Content li {
  border-right: $light-green-border;
}

.Content li:last-child {
  border-right: 0;
}

.ProductsButton {
  position: absolute;
  width: 38px;
  height: 38px;
  z-index: $second-layer;

  cursor: pointer;

  color: $cream;

  @media (min-width: $large-screen) {
    width: 48px;
    height: 48px;

    @media screen and (min-width: $large-screen) {
      transition: opacity 0.3s;

      opacity: 0.1;
    
      &:hover {
        opacity: 1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      padding: $spacing-large;

      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -2;
    }
  }
}

.PrevProduct {
  position: absolute;
  margin-left: $spacing-medium;

  @media (min-width: $large-screen) {
    margin-left: $spacing-large;
  }

  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.NextProduct {
  position: absolute;

  margin-right: $spacing-medium;

  @media (min-width: $large-screen) {
    margin-right: $spacing-large;
  }

  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.Arrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  color: $cream;

  & > div {
    cursor: pointer;
    width: 38px;

    @media (min-width: $large-screen) {
      width: 48px;
    }
  }
}

.ArrowLeft {
  transform: rotate(180deg);
}

.ArrowLeft,
.ArrowRight {
  transition: opacity 0.3s;
}
