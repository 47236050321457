@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  @include paddings;

  display: flex;
  flex-direction: column;
  height: auto;

  @media (min-width: $large-screen) {
    padding: $side-padding;
  
    height: 100%;
  }
}
.Title {
  text-align: left;
}

.Content {
  display: flex;
  height: 100%;
  flex-direction: column;

  @media (min-width: $large-screen) {
    flex-direction: row;
    margin-top: $spacing-large;

    & > div {
      width: 50%;
    }
  }
}

.LeftSide {
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 300px;

  @media (min-width: $large-screen) {
    height: 100%;
    overflow: visible;
  }
}

.Image {
  position: absolute;
  object-fit: cover;
  object-position: center;

  width: 100%;
  height: 100%;

  @media (min-width: $large-screen) {
    height: inherit;
    max-width: 100%;
    position: inherit;
  }
}

.RightSide {
  display: flex;
  flex-direction: column;
  color: $cream;
  justify-content: space-between;
  height: 100%;

  margin-top: $spacing-medium;

  @media (min-width: $large-screen) {
    padding: 0 $spacing-large;
    margin-top: 0;
  }
}

.Actions {
  display: flex;
  gap: $spacing-medium;

  width: 100%;

  margin-top: $spacing-large;

  & button {
    width: 100%;
  }

  @media (min-width: $medium-screen) {
    justify-content: flex-start;

    flex-direction: row;

    & button {
      width: auto;
    }
  }
}

.ProductInfo {
  @include label-font;

  display: flex;
  justify-content: flex-start;

  color: $orange;
}
