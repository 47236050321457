@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.ProductDescription {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  text-align: left;

  h1 {
    @include normal-title-font;

  }

  p {
    @include normal-text-font;

    margin-top: 0;

    max-height: 300px;
    overflow: scroll;
  }
}
