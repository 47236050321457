@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: flex;

  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 0 $spacing-large;
}

.Text {
  @include normal-title-font;
  padding: $spacing-large 0;
  color: $orange;
}

.Author {
  @include normal-text-font;

  color: $orange;
}