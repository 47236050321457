@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  position: relative;
  overflow: hidden;
  padding: $spacing-extra-large 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media (min-width: $large-screen) {
    padding: $spacing-extra-large;

    align-items: center;
  }
}

.Background {
  position: absolute;
  padding: $spacing-large 0 0;
  top: 0;
  left: -30px;

  z-index: 0;
}

.PatternImage {
  max-width: 100%;
  height: auto;
}

.Content {
  position: relative;
  background-color: $pink;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: $spacing-large 0;

  @media (min-width: $large-screen) {
    height: fit-content;
  }
}

.ArrowsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Arrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;

  color: $orange;
  height: 50px;

  margin: $spacing-large 0;

  &> div {
    width: 38px;

    @media (min-width: $large-screen) {
      width: 48px;
    }
  }
}