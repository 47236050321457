@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.Header {
  @include paddings;
  @include extra-large-title-font;

  color: $orange;
  border-bottom: 1px solid $sand;
}

.Content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $large-screen) {
    height: 100%;
    flex-direction: row;

    & > div {
      width: 50%;

      &:first-child {
        border-right: $sand-border;
      }
    }
  }
}

.LeftSide {
  padding: $spacing-large $spacing-medium;
  border-bottom: $sand-border;

  @media (min-width: $large-screen) {
    padding: $side-padding;
    border-bottom: 0;
  }

  h3 {
    @include large-title-font;

    color: white;
  }
}

.RightSide {
  @include normal-text-font;
  padding: $spacing-large $spacing-medium;

  color: $darkGreen;

  @media (min-width: $large-screen) {
    padding: $side-padding;
  }

  p:first-child {
    margin: 0;
  }
}
