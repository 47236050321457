@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Button {
  @include normal-text-font;

  display: flex;
  align-items: center;

  border-radius: 50px;
  border: none;
  padding: $spacing-medium * 1.2 $spacing-large * 1.2;

  height: fit-content;

  cursor: pointer;

  transition: transform 0.5s ease-out;
}

.Button:hover,
.Button:active {
  transform: scale(1.03);
}

.Disabled {
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    transform: scale(1);
  }
}

.Responsive {
  @include normal-text-font;
  border-radius: 0;
  padding: $spacing-medium * 1.2 $spacing-large * 1.2;
  height: auto;
  width: 100%;
  border-radius: 50px;

  @media screen and (min-width: $medium-screen) {
    @include normal-text-font;
    cursor: pointer;

    padding: $spacing-medium * 1.2 $spacing-large * 1.2;

    height: fit-content;

    transition: transform 0.5s ease-out;
  }
}

.Align__center {
  justify-content: center;
}

.Align__left {
  justify-content: flex-start;
}

.Align__right {
  justify-content: flex-end;
}
